/* renderMachine css */

.drag-n-drop {
  position: relative;
  width: 100%;
}

.templatePublishButton {
  border: 0;
  color: white;
  background-color: #300440;
  padding: 1em;
}

.builderMachine > div {
  border: 1px solid dimgray;
}

.builderMachineHeader {
  display: flex;
  background: #36444f;
  margin-bottom: 10px;
}

.drag-n-drop > .edit-options,
.drag-n-drop > .arrow {
  display: none;
}

.drag-n-drop:hover > .edit-options,
.drag-n-drop:hover > .arrow {
  display: flex;
}

.drag-n-drop {
  position: relative;
}

.drag-n-drop:hover:before {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  position: absolute;
  border: 2px dashed deepskyblue;
}

.drag-n-drop.activeTemplate:before {
  content: none;
}

.edit-options {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
}

.edit-options button {
  background: none;
  border: none;
  background: #d6d9d9;
  margin-left: 1px;
  font-size: 25px;
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}

.edit-options button:not([disabled]):hover {
  background: #ccebf3;
}
.edit-options button[disabled] {
  cursor: not-allowed;
}

.edit-options .arrow > i {
  font-size: 30px;
}

article {
  width: 100%;
}

.oneColumnLayout {
  padding: 0;
}

.tooltip-alert {
  font-size: 14px;
  min-width: 205px;
  right: 0;
  top: 45px;
}

.remove-btn.disabled:hover + .tooltip-alert {
  display: block !important;
}

.remove-btn:not(.disabled) {
  color: #e4496f;
}
