.chat-button {
    left: auto;
    background-color: #008060;
    color: white;
    right: 10px;
    bottom: 10px;
  }
  .btn, .btn:hover {
    background-color: #008060;
    border-color: #008060;
    color: white;
    height: 40px
  }
  .btn {
    font-size: 14px;
  }
  .custom-dialog {
    right: 25px;
    left: auto;
    max-width: 400px;
  }
  .customized-input {
    border-color: #858d94;
    font-size: 14px;
    padding: 8px 10px;
    height: auto;
  }

  body.modal-open {
    overflow: auto !important;
    padding-right: 0 !important;
  }
  
  .form-control {
    border-color: #aca1a1;
  }

  .error {
    color: red;
    font-size: 11px;
  }
   
  .style {
    padding-right: 0px;
  }
  
  #__talkjs_launcher {
    background-color: #008060;
  }
