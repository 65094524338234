.skeleton-box {
  display: inline-block;
  height: 3em;
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;
  opacity: 0.5;
  border-radius: 10px;
}

.sub {
  height: 2em !important;
}

.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  animation: skeleton 2s infinite;
  content: "";
  background: #dedddc;
  opacity: 0.5;
}

@keyframes skeleton {
  100% {
    transform: translateX(100%);
  }
}

.media {
  display: flex;
}

.media-body {
  flex-grow: 1;
  flex-basis: 80%;
}

* {
  box-sizing: border-box;
}

.box {
  flex-basis: 20%;
  height: 600px !important;
}

.h80 {
  height: 80px;
}

.h200 {
  height: 200px;
}

/*
.h90 {
  height: 90%;
}
.h83 {
  height: 83%;
}
.h70 {
  height: 70%;
}
 */