.template-toast .close {
  color: #fff;
}
.template-toast {
  font-size: 15px;
  bottom: 0;
  transition: bottom 0.15s linear;
}
.template-toast:before {
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  z-index: -1;
}
.template-toast.show {
  bottom: 50px;
}
.empty-header .close {
  position: absolute;
  right: 15px;
  top: 24px;
}
.toast-add > button {
  border: none;
  background: #e6e3e3;
  border-radius: 25px;
  padding: 10px;
  min-width: 282px;
  font-size: 14px;
}

.toast-add > button:hover {
  background: #d5d5d5;
}

.options .toast-header {
  background: #e6e3e3;
  justify-content: space-between;
}

.options .toast-body {
  background: #fff;
}
.empty-header .toast-body {
  background: #000;
  color: #fff;
}
.template-toast.drop {
  bottom: auto;
  top: 40%;
}
