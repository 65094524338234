/* Create site css */

.createWebpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url("/assets/bg.jpg") no-repeat center center/cover;
  position: relative;
  z-index: 1;
}

.createWebpage:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
  background: rgba(255, 255, 255, 0.5);
}

.createWebpage h1 {
  font-size: 30px;
  line-height: 1.2;
}

.createWebpage button {
  background: #607d8b;
  border-radius: 30px;
  height: 50px;
  min-width: 280px;
  color: white;
  border: 0px solid transparent;
}

.createWebpage .des {
  font-size: 22px;
}

.webpageTemplates {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.webpageTemplates div {
  margin: 0 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 230px;
  border-radius: 10px;
  text-transform: capitalize;
  background-color: #f14f73;
}

.activeTemplate {
  border: 2px dashed deepskyblue;
}

.webpageTemplates .container>*>p {
  color: black;
}

@media (min-width: 468px) {
  .webpageTemplates {
    flex-direction: column;
  }
}

@media (min-width: 996px) {
  .webpageTemplates {
    flex-direction: row;
  }
}