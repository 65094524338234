/* builder css */

.drop-zone.editor {
  min-height: 100vh;
  width: auto;
}

.resizable {
  resize: both;
  overflow: scroll;
  border: 1px solid black;
}

section {
  width: 100%;
}

.draggable {
  width: -webkit-fill-available;
}

.hide {
  display: none;
}

.builder {
  width: 100%;
  display: flex;
  padding: 70px 15px 0;
}

.provider.builder.template-updated {
  padding-top: 100px;
}
.h-100vh {
  height: 100vh;
}

span.last-updated-label {
  font-size: 13px;
  color: #b6b4b4;
}
.d-flex.justify-content-center.align-items-center.w-100.h-100vh {
  flex-direction: column;
}
.notification-icon:focus {
  box-shadow: none;
  outline: none;
}
.active-notification {
  width: 8px;
  height: 8px;
  background: red;
  top: 8px;
  right: 14px;
}

.builder-logo img {
  max-width: 200px;
  max-height: 35px;
}
.builder-logo strong {
  font-size: 24px;
}
