.empty.active-zone {
  min-height: 20px;
  margin: 20px 0;
  content: "Add component here";
}
.empty.active-zone::after {
  content: "Add component here";
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
