/* sidebar css */

.thumbnail {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 140px;
  position: relative;
  text-transform: capitalize;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.thumbnail img {
  width: 100%;
  max-height: 100%;
  pointer-events: none;
  object-fit: cover;
}

.thumbnail .label {
  font-size: 13px;
  position: absolute;
  left: 5px;
  top: 10px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 4px 10px;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
}

.toolbox-items .draggable {
  margin-bottom: 15px;
}

.builder .sideBarShow {
  transition: all 0.3s linear;
  background: #fff;
  padding: 0 0 60px;
  height: calc(100% - 95px);
  width: 380px;
  flex: 0 0 380px;
  margin-bottom: 20px;
  position: fixed;
  right: 0;
  z-index: 999;
  border-left: 1px solid #ccc;
}

.builder .template {
  margin-right: 380px;
  box-shadow: 0 0 0px 17px #efeeea;
}

.sideBarShow .tab-content > .active {
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.toolBox-properties.style {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  justify-content: space-between;
  font-size: medium;
  flex-direction: row-reverse;
}

/* .toolBox-properties.style > div {
  padding: 0 1px;
} */

.toolBox-properties {
  margin-bottom: 20px;
}

.toolBox-properties label {
  text-transform: capitalize;
  color: #666;
  font-size: 14px;
}
.text-field-wrapper {
  border: 1px solid #e5e1e1;
  padding-right: 7px;
  color: #666;
}
.toolBox-properties .style-text-field {
  width: 75px;
  margin-right: 8px;
  font-size: 0.9rem;
  color: #666;
  border-color: transparent #e5e1e1 transparent transparent;
}
.toolBox-properties > input {
  width: 100%;
  height: 35px;
  padding: 5px;
  border: 1px solid #bfbfbf;
  background: transparent;
  color: #686767;
  font-size: 15px;
}

.toolBox-properties > input:focus {
  outline: none;
}

.editableProps > span {
  display: flex;
  width: 100%;
  justify-content: center;
  text-decoration: underline;
  color: #000;
  padding: 20px;
}

.sideBarHide {
  padding: 0 !important;
}
.sideBarShow > .nav-tabs .nav-item.show .nav-link,
.sideBarShow > .nav-tabs .nav-link.active {
  /* background: #ccebf3; */
  color: #666;
  border-radius: 5px 5px 0 0;
  border-color: #dee2e6;
  border-bottom-color: transparent;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}

.sideBarShow .nav-tabs .nav-link {
  font-size: 14px;
  color: #413e3e;
  border: 1px solid transparent;
}

.sideBarShow .nav-tabs .nav-link.disabled {
  opacity: 0.7;
}

.sideBarShow .tab-content,
.toolbox-items {
  height: 100%;
}

.sideBarShow .nav-tabs .nav-link:focus {
  outline: none;
}

.editableProps .custom-select {
  font-size: 14px;
  min-width: 100px;
  border-radius: 0;
  padding: 5px;
}

.editableProps .custom-select:focus {
  box-shadow: none;
}

.editableProps {
  padding: 12px 15px 0;
}

.editableProps .nav-tabs {
  margin-bottom: 15px;
}
.provider .editableProps .nav-tabs {
  top: 104px;
}

.editableProps .nav-tabs .nav-link {
  background: transparent;
  transition: none;
  padding-top: 0;
  padding-bottom: 12px;
}

.editableProps .nav-tabs .nav-link:first-child {
  padding-left: 0;
}

.editableProps .nav-tabs .nav-link.active {
  font-weight: 500;
  color: #241c15;
}

.sideBarShow .card-header {
  background: transparent;
  cursor: pointer;
  font-size: 17px;
  border: none;
}

.sideBarShow .accordion > .card > .card-header span {
  border-bottom: 1px solid #dedddc;
  padding: 13px 30px 13px 15px;
  transition: all 0.3s ease-in-out;
}

.sideBarShow .accordion > .card > .card-header span:hover,
.sideBarShow .accordion > .card > .card-header span.open {
  background-color: #f2f2f2;
}

.sideBarShow .accordion > .card > .card-header span:after {
  content: "\f105";
  position: absolute;
  right: 15px;
  top: 10px;
  font-size: 22px;
  font-family: fontAwesome;
  transition: all 0.3s ease-in-out;
}

.sideBarShow .accordion > .card > .card-header span.open:after {
  transform: rotate(90deg);
}

.sideBarShow .accordion .card-body {
  max-height: 400px;
  overflow: auto;
}

.sideBarShow .accordion > .card > .card-header span.open:after {
  transform: rotate(90deg);
}

.sideBarShow .accordion .card-body {
  max-height: 400px;
  overflow: auto;
}
.label.disabled,
.draggable.used + .draggable.used .label {
  display: none;
}

.draggable.used + .draggable.used,
.draggable[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
.draggable.used + .draggable.used .label.disabled {
  display: block;
}

/* Sidebar Temp changes for V3.0 */

.accordion-footers,
.accordion-headers {
  display: none;
}
